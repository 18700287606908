import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { environment } from '@environment/environment';
import { NavController } from '@ionic/angular/standalone';
import { Participant } from '@model/participant';
import { PushNotification, PushType } from '@model/push-notification';
import { TranslateService } from '@ngx-translate/core';
import { AppDataService } from '@service/app-data.service';
import { PopupService } from '@service/popup-service';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  /* eslint-disable */
  private navController = inject(NavController);
  private ngZone = inject(NgZone);
  private popupService = inject(PopupService);
  private router = inject(Router);
  private appDataService = inject(AppDataService);
  private translationService = inject(TranslateService);

  appSettings = this.appDataService.appSettings;
  favorites = this.appDataService.favorites;
  unreadNotifications = this.appDataService.unreadNotifications;
  notifications = this.appDataService.notifications;

  isPushAvailable = false;
  loading = false;
  skipNavigateToRootOnce = false;
  /* eslint-enable */

  async initialize(): Promise<void> {
    const platform = Capacitor.getPlatform();
    const isNative = Capacitor.isNativePlatform();

    this.isPushAvailable = platform === 'android' || (platform === 'ios' && isNative);

    if (this.isPushAvailable) {
      await this.saveOpenNotifications();
    }

    console.log('Initialized PushService');
  }

  async register(): Promise<PermissionState> {
    if (this.isPushAvailable && (this.appSettings().pushOnNews || this.appSettings().pushOnFavorite)) {
      let permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt' || permissionStatus.receive === 'prompt-with-rationale') {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      console.log('`PushService - permissionStatus = ', permissionStatus);

      if (permissionStatus.receive === 'granted') {
        PushNotifications.removeAllListeners();
        this.addListeners();
        await PushNotifications.register();
        this.appSettings().pushOnFavorite ? this.subscribeToAllFavorites() : this.unsubscribeFromAllFavorites();
        this.appSettings().pushOnNews ? this.subscribeToNews(this.appSettings().pushTopicNews) : this.unsubscribeFromNews(this.appSettings().pushTopicNews);
      }

      else if (permissionStatus.receive === 'denied') {
        PushNotifications.removeAllListeners();
        this.unsubscribeFromNews(this.appSettings().pushTopicNews);
        await this.appDataService.togglePushOnNews(false);
        this.unsubscribeFromAllFavorites();
        await this.appDataService.togglePushOnFavorite(false);
      }

      return permissionStatus.receive as PermissionState;
    } else {
      console.log('No push needed');
    }

    return null;
  }

  async saveNotification(notification?: PushNotificationSchema): Promise<void> {
    if (!this.isPushAvailable) {
      return;
    }

    if (notification) {
      this.appDataService.addNotifications([new PushNotification(notification)]);
    }
  }

  async saveOpenNotifications(): Promise<void> {

    try {
      if (!this.isPushAvailable) {
        return;
      }

      const openNewsNotifications = await this.getOpenNotifications('news');
      const openPassageNotifications = await this.getOpenNotifications('passage');

      await PushNotifications.removeDeliveredNotifications({
        notifications: openNewsNotifications,
      });

      await this.appDataService.addNotifications(openNewsNotifications.map(n => new PushNotification(n)));

      await PushNotifications.removeDeliveredNotifications({
        notifications: openPassageNotifications,
      });

      this.appDataService.addUnreadPassageForFavorite(openPassageNotifications);
    } catch (error) {
      console.error('[PushService] Om de een of andere reden werkt dit niet op iOS', error);
    }
  }

  subscribeToAllFavorites(): void {
    if (this.isPushAvailable) {
      this.appDataService.favorites()
        .forEach(participant => {
          this.subscribeTo(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
        });
    }
  }

  subscribeToFavorite(participant: Participant): Promise<void> {
    if (this.isPushAvailable) {
      return this.subscribeTo(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
    }

    return Promise.resolve(null);
  }

  subscribeToNews(topic: string): void {
    if (this.isPushAvailable) {
      if (!environment.production) {
        // this.subscribeTo('TEST.AD6.2024.johanb');
      }
      this.subscribeTo(topic);
    }
  }

  async togglePushSetting(type: PushType, value?: boolean): Promise<boolean> {

    if (type === PushType.NEWS) {
      return await this.appDataService.togglePushOnNews(value);
    }
    if (type === PushType.FAVORITE) {
      return await this.appDataService.togglePushOnFavorite(value);
    }
  }

  async unregister(): Promise<void> {
    if (this.isPushAvailable) {
      await PushNotifications.unregister();
      await PushNotifications.removeAllListeners();
      await this.appDataService.setPushToken(null);
      await this.appDataService.togglePushOnNews(false);
      await this.appDataService.togglePushOnFavorite(false);
      this.unsubscribeFromNews(this.appSettings().pushTopicNews);
      this.unsubscribeFromAllFavorites();
    }
  }

  unsubscribeFromAllFavorites(): void {
    if (this.isPushAvailable) {
      this.appDataService.favorites().forEach(participant => {
        this.unsubscribeFrom(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
      });
    }
  }

  unsubscribeFromFavorite(participant: Participant): void {
    if (this.isPushAvailable) {
      this.unsubscribeFrom(`${this.appSettings().pushTopicParticipantPrefix}${participant.uuid}`);
    }
  }

  unsubscribeFromNews(topic: string): void {
    if (this.isPushAvailable) {
      if (!environment.production) {
        // this.unsubscribeFrom('TEST.AD6.2024.johanb');
      }
      this.unsubscribeFrom(topic);
    }
  }

  private addListeners(): void {
    PushNotifications.addListener('registration', async (token: Token) => {
      this.appDataService.setPushToken(token.value);
      console.log('Registered for push with token: ', token.value);
    });

    PushNotifications.addListener('registrationError', async (error: unknown) => {
      console.log('Error:', error);
      this.appDataService.setPushToken(null);
    });

    // Method called when a notification is received
    PushNotifications.addListener('pushNotificationReceived', (pushNotification: PushNotificationSchema) => {
      this.processPushNotification(pushNotification);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (pushNotificationAction: ActionPerformed) => {
      this.processPushNotificationAction(pushNotificationAction);
    });
  }

  private async getOpenNotifications(type: 'news' | 'passage'): Promise<PushNotificationSchema[]> {
    let openNotifications = (await PushNotifications.getDeliveredNotifications())?.notifications ?? [];
    openNotifications = openNotifications.filter(notification => !notification.groupSummary);

    let notificationsToReturn: PushNotificationSchema[] = [];

    if (Capacitor.getPlatform() === 'android') {
      // delivered notifications hebben op Android een id = 0. De notificatie die is binnengekomen heeft echter
      // wel een id. Die notification kun je daarop niet direct verwijderen, maar hij moet eerst in de delivered lijst worden
      // opgezocht. De beste match kan gemaakt worden via title, body en tag.
      // Op iOS speelt dit probleem niet. Daar heeft een delivered notification hetzelfdee id als de notification die verwerkt
      // wordt door de app.
      openNotifications = openNotifications.filter(notification => !notification.groupSummary && parseInt(notification.id) === 0);
      notificationsToReturn = openNotifications.filter(n => type === 'passage' ? !n.tag.startsWith('FCM-Notification:') : n.tag.startsWith('FCM-Notification:'));
    } else if (Capacitor.getPlatform() === 'ios') {
      notificationsToReturn = openNotifications.filter(n => type === 'passage' ? n.data.participant_uuid : !n.data.participant_uuid);
    }

    return notificationsToReturn;

  }

  private async processPushNotification(notification: PushNotificationSchema): Promise<void> {
    // When notification is received (only when app is open)
    console.log('processPushNotification', notification);
    // const pushNotificationExample = {
    //   body: "Open this now!",
    //   data: {
    //     text: "Open this now!",
    //     title: "Notification test",
    //     participant_uuid: "c787b0d6-3886-456a-8745-d5e12c338834",
    //   },
    //   id: "0:1650456586970884%b1c368a5b1c368a5",
    //   title: "Notification test",
    // }
    const data = notification.data;

    const currentUrl = this.router.routerState.snapshot.url;
    if (data.participant_uuid) {

      if (!(currentUrl.indexOf(data.participant_uuid) > -1)) {
        this.appDataService.addUnreadPassageForFavorite([notification]);
      }

      this.popupService.openToastMessage(
        notification.body || '',
        this.translationService.instant('message.arrivalNotification'),
      );
    } else {
      await this.saveNotification(notification);
      console.log(currentUrl);

      if (currentUrl !== '/tabs/more/notifications') {
        this.popupService.openToastMessage(
          notification.title || '',
          this.translationService.instant('message.pushNotification'),
        );
      }
    }
    this.removeFromDeliveredNotifications([notification]);

  }

  private async processPushNotificationAction(pushNotificationAction: ActionPerformed): Promise<void> {
    this.loading = true;
    this.skipNavigateToRootOnce = true;
    // When notification in system tray is tapped (app is closed)
    console.log('processPushNotificationAction:', pushNotificationAction);
    // const pushNotificationActionExample = {
    //   actionId: "tap",
    //   notification: {
    //     data: {
    //       text: "Open this now!",
    //       collapse_key: "nl.flusso.mobile.ad6.v2",
    //       from: "/topics/TEST.AD6.2022.news",
    //       google.delivered_priority: "high",
    //       google.original_priority: "high",
    //       google.sent_time: "1650457025672",
    //       google.ttl: "2419200",
    //       title: "Notification test",
    //       participant_uuid: "c787b0d6-3886-456a-8745-d5e12c338834",
    //     },
    //     id: "0:1650457026026732%b1c368a5b1c368a5",
    //   },
    // }
    const data = pushNotificationAction.notification.data;

    if (data.participant_uuid) {
      this.ngZone.run(() => {
        this.navController.navigateForward(['tabs', 'live'], {
          animated: true,
        }).then(() => this.navController.navigateForward(['/tabs/live/participant', data.participant_uuid], {
          animated: true,
        }));
      });
    } else {
      await this.saveNotification(pushNotificationAction.notification);

      this.ngZone.run(() => {
        this.navController.navigateForward(['tabs', 'more'], {
          animated: true,
        })
          .then(() => this.navController.navigateForward(['/tabs/more/notifications'], {
            animated: true,
          }))
          .then(() => this.loading = false);
      });
    }
  }

  private async removeFromDeliveredNotifications(notifications: PushNotificationSchema[]): Promise<void> {
    if (notifications.length === 0) {
      return;
    }

    try {
      const openNews = await this.getOpenNotifications('news');
      const openPassages = await this.getOpenNotifications('passage');
      let toRemove: PushNotificationSchema[] = [];
      let toRemoveOpenPassages: PushNotificationSchema[] = [];
      let toRemoveOpenNews: PushNotificationSchema[] = [];

      if (Capacitor.getPlatform() === 'android') {
        toRemoveOpenNews = openNews
          .filter(n => notifications.find(no => n.body === no.body && n.title === no.title) !== undefined)
          .filter(n => n !== undefined);

        toRemoveOpenPassages = openPassages
          .filter(n => notifications.find(no => n.tag === no.data.participant_uuid) !== undefined)
          .filter(n => n !== undefined);

        toRemove = [...toRemoveOpenNews, ...toRemoveOpenPassages];
      } else if (Capacitor.getPlatform() === 'ios') {
        toRemove = notifications;
      }

      await PushNotifications.removeDeliveredNotifications({
        notifications: toRemove,
      });
    } catch (error) {
      console.error('Error removing delivered notifications', error);
    }
  }

  private async subscribeTo(topic: string): Promise<void> {
    return FCM.subscribeTo({ topic })
      .then(success => {
        console.log(`FCM.subscribeTo('${topic}') success!`, success);
      })
      .catch(fail => {
        console.error(`FCM.subscribeTo('${topic}') failed!`, fail);
      });
  }

  private async unsubscribeFrom(topic: string): Promise<void> {
    return FCM.unsubscribeFrom({ topic })
      .then(success => {
        console.log(`FCM.unsubscribeFrom('${topic}') success!`, success);
      })
      .catch(fail => {
        console.error(`FCM.unsubscribeFrom('${topic}') failed!`, fail);
      });
  }

}
