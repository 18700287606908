import { TimeUtil } from '@app/utils/time-util';
import { PushNotificationSchema } from '@capacitor/push-notifications';

export enum PushType {
  FAVORITE = 'favorite',
  NEWS = 'news',
}
export interface PushNotificationExtendedSchema extends PushNotificationSchema {
  isRead: boolean;
}

export class PushNotification implements PushNotificationSchema {
  body: string;
  data: {
    title: string;
    text: string;
    participantUuid?: string;
    date: Date;
  };

  hasAction: boolean; // Not yet in use
  id: string;
  isRead = false;     // Not yet in use
  timePassed?: string;
  title: string;

  constructor(pushNotification: PushNotificationSchema | PushNotificationExtendedSchema) {
    this.id = pushNotification.id;
    this.title = pushNotification.title;
    this.body = pushNotification.body;
    this.data = { ...pushNotification.data };
    if (!this.data.date) {
      if (this.data['google.sent_time']) {
        this.data.date = new Date(parseInt(this.data['google.sent_time']));
      } else {
        this.data.date = new Date();
      }
    }
    if (this.title && !this.data.title) {
      this.data.title = this.title;
    }
    if (this.body && !this.data.text) {
      this.data.text = this.body;
    }
    this.timePassed = this.setTimePassed(this.data.date);
    if (Object.prototype.hasOwnProperty.call(pushNotification, 'isRead')) {
      this.isRead = (pushNotification as PushNotificationExtendedSchema).isRead;
    }
  }

  setTimePassed(date: Date | string): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return TimeUtil.formatAsTimePassedOrTime(date);
  }

  toObject(): string {
    return { ...this, timePassed: undefined };
  }
}
