import { format, formatDistanceToNow, isSameDay, parse } from 'date-fns';

export class TimeUtil {
  static timeToDate(time: string, timeDate = new Date()): Date {
    return parse(time, 'HH:mm', timeDate);
  }

  /**
   * Format a date as time passed or time.
   * If the date is today and the time is passed, it will be formatted as time passed (eg. "5 minutes ago").
   * Otherwise it will be formatted as time (eg. "14:30").
   */
  static formatAsTimePassedOrTime(timeDate: Date): string {
    const now = new Date();
    if (timeDate) {
      if (isSameDay(timeDate, now) && timeDate.getTime() < now.getTime()) {
        return formatDistanceToNow(timeDate, { addSuffix: true });
      }

      // Else format as time
      return format(timeDate, 'HH:mm');
    }

    return '';
  }
}
